import Swiper from "swiper";
import SwiperCore, { EffectFade, Autoplay } from "swiper/core";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([EffectFade, Autoplay]);

const swiper = new Swiper(".FrontpageHero__slider", {
	effect: "fade",
	fadeEffect: {
		crossFade: true,
	},
	loop: true,
	autoplay: true,
	speed: 500,
	autoplaySpeed: 3000,
});

swiper.on("slideChange", function() {
	setActiveElement();
});

const setActiveElement = (elementID) => {
	const currentElement = swiper.slides[swiper.activeIndex];
	const currentElementID = elementID || currentElement?.getAttribute("data-id");
	switchMenuLinksClass(currentElementID);
};

const switchMenuLinksClass = (currentID) => {
	document
		.querySelectorAll(`.FrontpageHero__links ul li`)
		.forEach((link) => link.classList.remove("active"));
	document
		.querySelector(`.FrontpageHero__links ul li[data-id="${currentID}"]`)
		?.classList.add("active");
};

setActiveElement();
