import Swiper from "swiper";
import SwiperCore, { Autoplay } from "swiper/core";

SwiperCore.use([Autoplay]);

const swiper = new Swiper(".SliderBlock .swiper-container", {
	loop: true,
	autoplay: true,
	speed: 500,
	autoplaySpeed: 3000,
	slidesPerView: 1,
	grabCursor: true,
	breakpoints: {
		768: {
			slidesPerView: 2,
		},
	},
});
